<template>
  <v-app>
    <v-banner single-line class="banner">
      <v-img
        src="https://h5.ophyer.cn/official_website/other/H5SasscloundBanner.png"
        height="100%"
        :transition="false"
        class="d-flex align-center"
        
      >
        <template v-slot:placeholder>
          <v-row class="fill-height ma-0" align="center" justify="center">
            <v-progress-circular
              indeterminate
              size="80"
              color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="d-flex  max-width-1200">
          <v-card color="transparent" flat >
            <div class="d-flex flex-no-wrap justify-space-between">
              <div>
                <v-card-title class="white--text font-size-h5banner-title" >
                  万界云
                </v-card-title>
                <v-card-text class="white--text py-0  font-size-h5banner-tip">
                 技术+私域+运营赋<br/>致力于品牌更深度的服务
                </v-card-text>
              </div>
            </div>
          </v-card>
        </v-container>
      </v-img>
    </v-banner>
    <!-- 多场景营销，持续提升经营指标 -->
    <v-card flat class="width-per-100 pb-6" style="background: #F5F8FC;">
      <!-- <v-card-title class="justify-center font-weight-bold font-size-20 color-333 pa-0 mt-6 line-height-28">多场景营销，持续提升经营指标</v-card-title> -->
      <v-card-title class="justify-center font-weight-bold font-size-20 color-333 pa-0 mt-6 line-height-28">多场景运营，持续提升经营指标</v-card-title>
      <div class="mt-4 px-3">
        <v-row v-for="(row,rowIndex) in data.products" :key="rowIndex">
          <v-col col="6" class="d-flex justify-center"
                 style="padding: 0 5px;"
                 v-for="(col,colIndex) in row" :key="colIndex">
            <v-card flat class="pa-3 h5-card "
                    :min-height="$vuetify.breakpoint.name === 'xs'?95:78"
                    width="100%">
              <div class="d-flex justify-center">
                <v-img :src="col.img" width="48" height="48" contain></v-img>
              </div>
              <v-card-title class="pa-0 font-size-16 line-height-22 justify-center font-weight-bold color-333 mt-2">
                {{col.title}}
              </v-card-title>
              <v-card-text class="pa-0 mt-1 ml-1 text-align-center">
                <span class="font-size-12 color-999 font-weight-300 line-height-17">{{col.explain}}</span>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <!-- 万界云产品体系 -->
    <v-card color="#2181EA" flat class="py-6 position-r">
      <v-card-title class="justify-center font-weight-bold font-size-20 color-FFF pa-0 line-height-28">万界云产品体系</v-card-title>
      <v-tabs v-model="tab" background-color="transparent" color="white" dark
              class="xs-tab">
        <v-tab v-for="(v, k) in data.industrySolutions" :key="k" class="pa-0"
               :class="[k === 0 ? 'ml-5':'ml-6',k === data.industrySolutions.length - 1 ? 'mr-5':'']"
               style="min-width: unset">
          <b class="color-FFF font-size-16 letter-spacing">{{ v.title }}</b>
        </v-tab>
      </v-tabs>
      <v-tabs-items class="mt-3 mx-5" v-model="tab" style="border-radius: 10px;">
        <v-tab-item v-for="(v, k) in data.industrySolutions" :key="k" :transition="false">
          <v-card flat color="white" class="pa-5">
            <v-img :src="v.img" width="100%" min-height="200" contain>
              <template v-slot:placeholder><img-placeholder></img-placeholder></template>
            </v-img>
            <v-card-title class="pa-0 mt-4 font-size-18 font-weight-bold line-height-25">{{v.lunTitle}}</v-card-title>
              <v-card-text class="pa-0 my-1 line-height-26"  v-text="v.text" />
            <!-- <v-card-text class="pa-0 mt-2 font-size-15 color-555 line-height-21" v-text="v.tip" /> -->
            <!-- <div class="mt-3 font-size-13 color-666"> -->
              <!-- <v-card-text class="pa-0 my-1 line-height-26" v-for="(i, j) in v.text" :key="j" v-text="i" /> -->
            <!-- </div> -->
          </v-card>
        </v-tab-item>
      </v-tabs-items>
      <div class="tab-btn-prev" @click.stop="handleTabBtn('prev')"></div>
      <div class="tab-btn-next" @click.stop="handleTabBtn('next')"></div>
    </v-card>
    <!-- 万界云产品优势 -->
    <!-- <v-card class="px-4 py-6" flat>
      <v-card-title class="justify-center font-weight-bold font-size-20 pa-0 line-height-28">万界云产品优势</v-card-title>
      <div class="mt-4">
        <v-row v-for="(row,rowIndex) in data.advantageList" :key="rowIndex">
          <v-card class="pa-3 d-flex h5-card" min-height="90" width="100%" flat>
            <v-img :src="row.img" max-width="65" contain position="top"></v-img>
            <div class="ml-4">
              <v-card-title class="pa-0 mt-1 font-size-16 font-weight-bold line-height-20">
                {{row.title}}
              </v-card-title>
              <v-card-text class="pa-0 mt-2 font-size-13 color-666 line-height-20">
                {{row.text}}
              </v-card-text>
            </div>
          </v-card>
        </v-row>
      </div>
    </v-card> -->
    <!-- 合作伙伴 -->
    <!--<v-card class="py-5 px-2 pb-2" flat color="#F5F8FC">-->
    <!--  <v-card-title class="justify-center font-weight-bold font-size-20 pa-0 line-height-28">合作伙伴</v-card-title>-->
    <!--  <v-img class="mt-3" width="100%" contain src="https://h5.ophyer.cn/official_website/other/saacloundCaseH51.png">-->
    <!--    <template v-slot:placeholder><img-placeholder></img-placeholder></template>-->
    <!--  </v-img>-->
    <!--</v-card>-->
  </v-app>
</template>

<script>
import contentData from '@/static/contentData.js';
export default {
  name: 'h5SaasCloud',
  components: {
  },
  data() {
    return {
      data: contentData.SaasCloudh5,
      tab: 0,
    }
  },
  watch: {
  },
  created() {
  },
  methods: {
    handleTabBtn: function (type){
      const maxTab = this.data.industrySolutions.length - 1;
      if(type === "prev"){
        if(this.tab === 0){
          this.tab = maxTab;
        }else {
          this.tab--;
        }
      }else if(type === "next"){
        if(this.tab === maxTab){
          this.tab = 0;
        }else {
          this.tab++;
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.banner{
  position: relative;
  ::v-deep .v-banner__wrapper,::v-deep .v-banner__content,::v-deep .v-banner__text{
    padding: 0 !important;
    height: 100%;
  }
  .banner-box {
    margin-top: -100px;
    .banner-text {
      p:nth-child(1) {
        font-size: 82px;
        //font-family: SourceHanSansCN-Regular;
        font-weight: 400;
        color: #fff;
        line-height: 88px;
      }
      p:nth-child(2) {
        margin-top: 25px;
        font-size: 24px;
        font-weight: 100 !important;
        color: #fff;
      }
      .banner-btn {
        margin-top: 90px;
        width: 200px;
        height: 60px;
        background: #0568FD;
        border-radius: 35px;
        font-size: 20px !important;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 60px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
  @media (min-width: 960px) {
    .container {
      max-width: 1200px !important;
    }
  }
  
}
</style>
